
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ResourceActionFailed } from '@movecloser/front-core'

import { Inject } from '../../../../support'

import { ILoyaltyService, LoyaltyServiceType } from '../../contracts/services'

import { BenefitProgramResignFormProps } from './BenefitProgramResignForm.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({ name: 'BenefitProgramResignForm' })
export class BenefitProgramResignForm extends Vue {
  @Inject(LoyaltyServiceType)
  protected readonly loyaltyService!: ILoyaltyService

  @Prop({ type: Object, required: true })
  public readonly program!: BenefitProgramResignFormProps['program']

  @Prop({ type: Object, required: true })
  public readonly payload!: BenefitProgramResignFormProps['payload']

  public error: string | null = null
  public loading: boolean = false

  public async onConfirm (): Promise<void> {
    this.loading = true

    try {
      const payload = { ...this.payload, [this.program.id]: null }

      await this.loyaltyService.update(payload)
      this.$emit('confirm', this.program.id)
    } catch (e) {
      this.error = (e as ResourceActionFailed).message
    } finally {
      this.loading = false
    }
  }

  public onCancel (): void {
    this.$emit('cancel')
  }
}

export default BenefitProgramResignForm
