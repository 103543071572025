




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { benefitLogo } from '../../shared'

import { BenefitProgramSelectorProps } from './BenefitProgramSelector.contracts'
import BenefitProgramSelectorItem from './partials/BenefitProgramSelectorItem.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<BenefitProgramSelector>({
  name: 'BenefitProgramSelector',
  components: { BenefitProgramSelectorItem }
})
export class BenefitProgramSelector extends Vue {
  @Prop({ type: Object, required: true })
  public readonly payload!: BenefitProgramSelectorProps['payload']

  @Prop({ type: Array, required: false, default: [] })
  public readonly errors?: BenefitProgramSelectorProps['errors']

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCheckout?: BenefitProgramSelectorProps['isCheckout']

  @Prop({ type: Array, required: true })
  public readonly programs!: BenefitProgramSelectorProps['programs']

  @Prop({ type: Boolean, required: false, default: false })
  public readonly showNone!: BenefitProgramSelectorProps['showNone']

  public cardNumber = null
  public selectedProgram = null
  public thanksMessage: string = ''
  public thanksLogo: string = ''

  @Watch('selectedProgram')
  private async onSelect () {
    if (this.selectedProgram === 'none') {
      this.$emit('success', this.selectedProgram)
    }
  }

  @Watch('cardNumber')
  private async onSuccess () {
    if (!this.selectedProgram) {
      return
    }

    this.thanksMessage = this.$t(
      `front.loyalty.organisms.BenefitPrograms.programs.${this.selectedProgram}.thanks`,
      { cardNumber: this.cardNumber }
    ).toString()
    this.thanksLogo = benefitLogo[this.selectedProgram]

    this.$emit('success', this.selectedProgram)
  }
}

export default BenefitProgramSelector
